import { User, Deduction, Person, Rate } from '.';

export class Timelog {
  id?: string;
  absent?: boolean;
  chargedAmount?: number;
  date?: Date;
  deduction?: Deduction;
  deductionId?: string;
  hours?: number;
  initial?: boolean;
  note?: string;
  nurturer?: Person;
  nurturerId?: string;
  participant?: Person;
  participantId?: string;
  rate?: Rate;
  rateId?: string;

  // For UI purposes this is set when its deleted
  deleted?: boolean;
}
