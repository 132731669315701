import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { Router } from '@angular/router';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from '../../models';
import { ApiService } from 'src/app/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditUserPasswordModalComponent } from '../../modals/edit-user-password/edit-user-password.modal';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;
  public user: User;
  private userSub: Subscription;

  constructor(
    location: Location,
    private router: Router,
    private store: Store<AppState>,
    private api: ApiService,
    private modalService: NgbModal
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.userSub = this.store
      .select((state: any) => state.appState)
      .subscribe(({ user }) => (this.user = user));
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  // async changePassword() {
  //   const modalRef = this.modalService.open(EditUserPasswordModalComponent)
  //   modalRef.componentInstance.user = this.user
  // }

  async logout() {
    await this.api.clearSession();
    this.router.navigateByUrl('/auth/login');
  }
}
