import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  PersonService,
  AlertService,
  ConnectionResponse,
  ApiService,
} from '../../services';
import { Pagination, PaginationVariables, Person, Center } from '../../models';
import { PEOPLE_STATUSES, PEOPLE_MODES } from '../../constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { EditPersonModalComponent } from '../../modals/edit-person/edit-person.modal';

@Component({
  selector: 'app-overdue-list',
  templateUrl: './overdue-list.component.html',
  styleUrls: ['./overdue-list.component.scss'],
})
export class OverdueListComponent implements OnInit, OnDestroy {
  public paginator: Pagination = new Pagination(5);
  public data: Array<any>;
  public loading = false;
  public center: Center;
  private centerSub: Subscription;

  constructor(
    private personService: PersonService,
    private alert: AlertService,
    private modalService: NgbModal,
    private api: ApiService
  ) {
    this.centerSub = this.api
      .currentCenter()
      .subscribe((center) => (this.center = center));
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.centerSub.unsubscribe();
  }

  nextPage() {
    this.loadData(this.paginator.nextVariables());
  }

  previousPage() {
    this.loadData(this.paginator.previousVariables());
  }

  async loadData(variables: PaginationVariables = null) {
    if (!variables) {
      variables = this.paginator.firstVariables();
    }

    let res: ConnectionResponse;
    this.loading = true;
    try {
      const options: any = {
        ...variables,
        mode: 'PARTICIPANT',
        overdue: true,
      };

      res = await this.personService.list(options);
    } catch (e) {
      this.alert.error(e);
    } finally {
      this.loading = false;
    }

    this.paginator.updatePageInfo(res.pageInfo);
    this.data = res.nodes;
  }
}
