import { Person } from '.';

export class Payment {
  id?: string;
  date?: Date;
  method?: string;
  amount?: number;
  note?: string;
  participantId?: string;
  participant?: Person;
  updatedAt?: string;

  // For UI purposes this is set when its deleted
  deleted?: boolean;
}
