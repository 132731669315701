export class Person {
  id?: string;
  name?: string;
  email?: string;
  mode?: string;
  balance?: number;
  hours?: number;
  status?: string;
  birthday?: Date;
  createdAt?: Date;
  phone?: string;
  tagList?: Array<string> | string;

  // For UI purposes this is set when its deleted
  deleted?: boolean;
}
