export class User {
  id?: string;
  name?: string;
  email?: string;
  password?: string;
  status?: string;
  roles?: Array<string>;
  updatedAt?: string;

  // For UI purposes this is set when its deleted
  deleted?: boolean;
}
