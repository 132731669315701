import { Injectable } from '@angular/core';
import { Person, Pagination } from '../models';
import { ConnectionResponse } from './api.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get, pick } from 'lodash';

@Injectable()
export class StatisticsService {
  constructor(private apollo: Apollo) { }

  centerPayments(variables: any = {}): Promise<Array<any>> {
    return this.apollo.query({
      query: gql`
      query getCenterPaymentStats (
        $granularity: Granularity!
        $last: Int
        $range: Timerange
        $fields: [PaymentStatisticFields!]!
        $series: Boolean
      ) {
        viewer {
          center {
            stats: paymentStatistics(
              granularity: $granularity
              last: $last
              range: $range
              fields: $fields
              series: $series
            )
          }
        }
      }`,
      variables
    })
    .toPromise()
    .then(data => get(data, 'data.viewer.center.stats'));
  }

  centerTimelogs(variables: any = {}): Promise<Array<any>> {
    return this.apollo.query({
      query: gql`
      query getCenterTimelogStats (
        $granularity: Granularity!
        $last: Int
        $range: Timerange
        $fields: [TimelogStatisticFields!]!
        $series: Boolean
      ) {
        viewer {
          center {
            stats: timelogStatistics(
              granularity: $granularity
              last: $last
              range: $range
              fields: $fields
              series: $series
            )
          }
        }
      }`,
      variables
    })
    .toPromise()
    .then(data => get(data, 'data.viewer.center.stats'));
  }

  personPayments(variables: any = {}): Promise<Array<any>> {
    return this.apollo.query({
      query: gql`
      query getPersonPaymentStats (
        $personId: ID!
        $granularity: Granularity!
        $last: Int
        $range: Timerange
        $fields: [PaymentStatisticFields!]!
        $series: Boolean
      ) {
        viewer {
          center {
            people(
              id: $personId
              first: 1
            ) {
              nodes {
                stats: paymentStatistics(
                  granularity: $granularity
                  last: $last
                  range: $range
                  fields: $fields
                  series: $series
                )
              }
            }
          }
        }
      }`,
      variables
    })
    .toPromise()
    .then(data => get(data, 'data.viewer.center.people.nodes.0.stats'));
  }

  personTimelogs(variables: any = {}): Promise<Array<any>> {
    return this.apollo.query({
      query: gql`
      query getPersonTimelogStats (
        $personId: ID!
        $granularity: Granularity!
        $last: Int
        $range: Timerange
        $fields: [TimelogStatisticFields!]!
        $series: Boolean
      ) {
        viewer {
          center {
            people(
              id: $personId
              first: 1
            ) {
              nodes {
                stats: timelogStatistics(
                  granularity: $granularity
                  last: $last
                  range: $range
                  fields: $fields
                  series: $series
                )
              }
            }
          }
        }
      }`,
      variables
    })
    .toPromise()
    .then(data => get(data, 'data.viewer.center.people.nodes.0.stats'));
  }

}
