import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  RateService,
  AlertService,
  ConnectionResponse,
  ApiService,
} from '../../services';
import { Pagination, PaginationVariables, Rate, Center } from '../../models';
import { USER_ROLES, USER_STATUSES } from '../../constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rate-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="rate.id">Editing {{ rate.name }}</h4>
      <h4 class="modal-title" *ngIf="!rate.id">Add Rate</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input
        placeholder="Name"
        [(ngModel)]="rate.name"
        class="form-control mb-3"
      />
      <input
        *ngIf="!rate.id"
        type="number"
        placeholder="Amount"
        [(ngModel)]="rate.amount"
        class="form-control mb-3"
      />
      <textarea
        class="form-control mt-3"
        [(ngModel)]="rate.description"
        placeholder="Description..."
      ></textarea>

      <div
        *ngIf="rate.amount && center"
        class="text-center text-xl bg-secondary p-4 rounded border mt-3"
      >
        {{ rate.amount | currency : [center.currency] }} per hour
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-block" (click)="save()">
        Save
      </button>
    </div>
  `,
})
export class EditRateModalComponent implements OnInit, OnDestroy {
  @Input() rate: Rate = {};
  public roles: Array<string> = USER_ROLES;
  public selectedRole: string = USER_ROLES[0];
  public statuses: Array<string> = USER_STATUSES;
  public selectedStatus: string = USER_STATUSES[0];
  public centerSub: Subscription;
  public center: Center;

  constructor(
    public activeModal: NgbActiveModal,
    private rateService: RateService,
    private alert: AlertService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.centerSub = this.api
      .currentCenter()
      .subscribe((center) => (this.center = center));
  }

  ngOnDestroy() {
    this.centerSub.unsubscribe();
  }

  async save() {
    const { rate } = this;

    if (rate.id) {
      try {
        await this.rateService.update(rate.id, rate);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully updated ${rate.name}`);
    } else {
      try {
        await this.rateService.create(rate);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully added ${rate.name}`);
    }

    this.activeModal.close();
  }
}
