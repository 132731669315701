import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from './services';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'lcm';

  constructor(
    private gtmService: GoogleTagManagerService,
  ) {}

  ngOnInit() {
    if (environment.production) {
      // Do some virtual page tracking from GTM
      this.gtmService.addGtmToDom();
    }
  }
}
