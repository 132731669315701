import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  PersonService,
  AlertService,
  ConnectionResponse,
  ApiService,
} from '../../services';
import { Pagination, PaginationVariables, Person, Center } from '../../models';
import { PEOPLE_STATUSES, PEOPLE_MODES } from '../../constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-person-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="person.id">Editing {{ person.name }}</h4>
      <h4 class="modal-title" *ngIf="!person.id">Add Person</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input
        placeholder="Name"
        [(ngModel)]="person.name"
        class="form-control mb-3"
      />
      <input
        placeholder="Email"
        [(ngModel)]="person.email"
        class="form-control mb-3"
      />

      <input
        type="text"
        placeholder="Birthday"
        class="inline form-control input-sm mb-3"
        [(ngModel)]="person.birthday"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
        bsDatepicker
      />

      <input
        placeholder="Phone"
        [(ngModel)]="person.phone"
        class="form-control mb-3"
      />

      <tag-input
        class="mb-3"
        modelAsStrings="true"
        [(ngModel)]="person.tagList"
        [editable]="true"
      ></tag-input>

      <div class="row">
        <div class="col" [hidden]="person.id">
          <div
            class="btn-group btn-group-toggle"
            ngbRadioGroup
            name="mode"
            [(ngModel)]="person.mode"
          >
            <label
              ngbButtonLabel
              class="btn-outline-primary btn-sm"
              *ngFor="let mode of modes"
            >
              <input ngbButton type="radio" [value]="mode" /> {{ mode }}
            </label>
          </div>
        </div>
        <div class="col">
          <div
            class="btn-group btn-group-toggle mx-2"
            ngbRadioGroup
            name="status"
            [(ngModel)]="person.status"
          >
            <label
              ngbButtonLabel
              class="btn-outline-primary btn-sm"
              *ngFor="let status of statuses"
            >
              <input ngbButton type="radio" [value]="status" /> {{ status }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-block" (click)="save()">
        Save
      </button>
    </div>
  `,
})
export class EditPersonModalComponent {
  @Input() person: Person = {};
  public statuses: Array<string> = PEOPLE_STATUSES;
  public selectedStatus: string = PEOPLE_STATUSES[0];
  public modes: Array<string> = PEOPLE_MODES;
  public selectedMode: string = PEOPLE_MODES[0];

  constructor(
    public activeModal: NgbActiveModal,
    private personService: PersonService,
    private alert: AlertService
  ) {}

  changeStatus(status) {
    this.selectedStatus = status;
  }

  changeMode(mode) {
    this.selectedMode = mode;
  }

  async save() {
    const { person } = this;

    if (person.id) {
      try {
        await this.personService.update(person.id, person);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully updated ${person.name}`);
    } else {
      try {
        await this.personService.create(person);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully added ${person.name}`);
    }

    this.activeModal.close();
  }
}
