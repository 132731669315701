import { NgModule } from '@angular/core';
import {
  ApiService, PaymentService, TimelogService,
  PersonService, CenterService, RateService, DeductionService,
  UserService, StatisticsService, BillingService
} from '.';

@NgModule({
  providers: [
    ApiService,
    PaymentService,
    TimelogService,
    PersonService,
    CenterService,
    RateService,
    DeductionService,
    UserService,
    BillingService,
    StatisticsService,
  ]
})
export class ServicesModule { }
