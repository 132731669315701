import { Injectable } from '@angular/core';
import { Person, Pagination } from '../models';
import { ConnectionResponse } from './api.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get, pick } from 'lodash';

@Injectable()
export class UserService {
  constructor(private apollo: Apollo) {}

  list(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo
      .query({
        query: gql`
          query listAllUsers(
            $search: String
            $first: Int
            $last: Int
            $after: String
            $before: String
            $status: UserStatus
          ) {
            viewer {
              center {
                users(
                  query: $search
                  first: $first
                  last: $last
                  after: $after
                  before: $before
                  status: $status
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    name
                    avatar(size: 36)
                    avatarLarge: avatar(size: 180)
                    email
                    roles
                    status
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.users'));
  }

  create(data: Person): Promise<Person> {
    const input = pick(data, ['name', 'email', 'status']);
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($input: UserAttributes!) {
            userCreate(input: $input) {
              id
              name
            }
          }
        `,
        variables: { input },
      })
      .toPromise()
      .then((res) => get(res, 'data.userCreate'));
  }

  update(id: string, data: Person): Promise<Person> {
    const input = pick(data, ['name', 'email', 'status']);
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!, $input: UserAttributes!) {
            userUpdate(userId: $id, input: $input) {
              id
              name
            }
          }
        `,
        variables: {
          id,
          input,
        },
      })
      .toPromise()
      .then((res) => get(res, 'data.userUpdate'));
  }

  delete(id: string): Promise<Person> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!) {
            userDelete(userId: $id) {
              id
              name
            }
          }
        `,
        variables: { id },
      })
      .toPromise()
      .then((data) => get(data, 'data.userDelete'));
  }
}
