import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UserService,
  AlertService,
  ConnectionResponse,
  ApiService,
} from '../../services';
import { Pagination, PaginationVariables, User, Center } from '../../models';
import { USER_ROLES, USER_STATUSES } from '../../constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-password-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Update Password</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input
        type="password"
        placeholder="Password"
        [(ngModel)]="user.password"
        class="form-control mb-3"
      />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-block" (click)="save()">
        Update
      </button>
    </div>
  `,
})
export class EditUserPasswordModalComponent {
  @Input() user: User = {};

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private alert: AlertService
  ) {}

  async save() {
    const { user } = this;

    try {
      await this.userService.update(user.id, user);
    } catch (e) {
      this.alert.error(e);
      return;
    }

    this.alert.success(`Successfully updated the password`);

    this.activeModal.close();
  }
}
