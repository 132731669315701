import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  DeductionService,
  RateService,
  TimelogService,
  AlertService,
  ConnectionResponse,
  ApiService,
  PersonService,
} from '../../services';
import {
  Pagination,
  PaginationVariables,
  Center,
  Timelog,
  Person,
  Deduction,
  Rate,
} from '../../models';
import { Subscription, Observable, Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { get, debounce } from 'lodash';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-timelog-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="timelog.id">Edit Timelog</h4>
      <h4 class="modal-title" *ngIf="!timelog.id">Add Timelog</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3" [hidden]="!populatedTimelog">
        <alert type="info">
          We pre-populated a few fields from the last time
          <strong>{{ populatedTimelog?.participant?.name }}</strong> took a
          session on <strong>{{ populatedTimelog?.date | date }}</strong
          >.
        </alert>
      </div>
      <div class="row">
        <div class="col">
          <ng-select
            class="mb-3"
            placeholder="Participant"
            bindLabel="label"
            bindValue="id"
            [items]="participants"
            [(ngModel)]="timelog.participantId"
            [typeahead]="participantInput$"
            [loading]="participantsLoading"
            (ngModelChange)="loadParticipantTimelog($event)"
          ></ng-select>
        </div>
        <div class="col">
          <ng-select
            class="mb-3"
            placeholder="Nurturer"
            bindLabel="label"
            bindValue="id"
            [items]="nurturers"
            [(ngModel)]="timelog.nurturerId"
            [typeahead]="nurturerInput$"
            [loading]="nurturersLoading"
          ></ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ng-select
            class="mb-3"
            placeholder="Rate"
            bindLabel="name"
            bindValue="id"
            [items]="rates"
            [(ngModel)]="timelog.rateId"
            [typeahead]="rateInput$"
            [loading]="ratesLoading"
          ></ng-select>
        </div>
        <div class="col">
          <ng-select
            class="mb-3"
            placeholder="Deduction"
            bindLabel="name"
            bindValue="id"
            [items]="deductions"
            [(ngModel)]="timelog.deductionId"
            [typeahead]="deductionInput$"
            [loading]="deductionsLoading"
          ></ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input
            type="number"
            placeholder="Hours"
            [(ngModel)]="timelog.hours"
            class="form-control mb-3"
          />
        </div>
        <div class="col">
          <input
            type="text"
            placeholder="Date"
            class="inline form-control input-sm mb-3"
            [(ngModel)]="timelog.date"
            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
            bsDatepicker
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <textarea
            class="form-control mt-3"
            [(ngModel)]="timelog.note"
            placeholder="Private note..."
          ></textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <div
            class="custom-control custom-control-alternative custom-checkbox"
          >
            <input
              [(ngModel)]="timelog.absent"
              class="custom-control-input"
              id="asbentCheck"
              type="checkbox"
            />
            <label class="custom-control-label" for="asbentCheck">
              <span class="text-muted">Absent</span>
            </label>
          </div>
        </div>
        <div class="col">
          <div
            class="custom-control custom-control-alternative custom-checkbox"
          >
            <input
              [(ngModel)]="timelog.initial"
              class="custom-control-input"
              id="initialCheck"
              type="checkbox"
            />
            <label class="custom-control-label" for="initialCheck">
              <span class="text-muted">Initial hours (no charge)</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="timelog.id"
        type="button"
        [class.btn-loading]="deleting"
        class="btn btn-danger"
        (click)="destroy()"
      >
        Delete
      </button>
      <button
        type="button"
        [class.btn-loading]="saving"
        class="btn btn-primary"
        (click)="save()"
      >
        Save
      </button>
    </div>
  `,
})
export class EditTimelogModalComponent implements OnInit, OnDestroy {
  @Input() timelog: Timelog = {};
  public saving: Boolean = false;
  public deleting: Boolean = false;
  public creating: Boolean = true;
  public participants: Array<Person> = [];
  public nurturers: Array<Person> = [];
  public rates: Array<Rate> = [];
  public deductions: Array<Deduction> = [];
  public populatedTimelog: any = null;

  public participantInput$: Subject<string> = new Subject<string>();
  public nurturerInput$: Subject<string> = new Subject<string>();
  public rateInput$: Subject<string> = new Subject<string>();
  public deductionInput$: Subject<string> = new Subject<string>();

  public participantsLoading = false;
  public nurturersLoading = false;
  public ratesLoading = false;
  public deductionsLoading = false;

  public participantSearch: string;
  public nurturerSearch: string;
  public rateSearch: string;
  public deductionSearch: string;

  public center: Center;
  private centerSub: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private timelogService: TimelogService,
    private personService: PersonService,
    private deductionsService: DeductionService,
    private ratesService: RateService,
    private alert: AlertService,
    private api: ApiService
  ) {
    this.centerSub = this.api
      .currentCenter()
      .subscribe((center) => (this.center = center));
  }

  async ngOnInit() {
    this.creating = !this.timelog?.id;

    // If the participant is set, but nothing else, load session data to pre-populate
    // This is used for when modals have the participant pre set
    const participantId = this.timelog?.participant?.id;
    if (participantId && this.creating) {
      await this.loadParticipantTimelog(participantId);
    } else {
      this.initData();
    }
  }

  async initData() {
    const { timelog } = this;

    if (timelog.participant) {
      this.timelog.participantId = timelog.participant.id;
      this.participantSearch = timelog.participant.name;
    }
    if (timelog.nurturer) {
      this.timelog.nurturerId = timelog.nurturer.id;
      this.nurturerSearch = timelog.nurturer.name;
    }
    if (timelog.rate) {
      this.timelog.rateId = timelog.rate.id;
      this.rateSearch = timelog.rate.name;
    }
    if (timelog.deduction) {
      this.timelog.deductionId = timelog.deduction.id;
      this.deductionSearch = timelog.deduction.name;
    }

    this.loadParticipants();
    this.participantInput$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.participantSearch = value;
        this.loadParticipants();
      });

    this.loadNurturers();
    this.nurturerInput$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.nurturerSearch = value;
        this.loadNurturers();
      });

    this.loadRates();
    this.rateInput$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.rateSearch = value;
        this.loadRates();
      });

    this.loadDeductions();
    this.deductionInput$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.deductionSearch = value;
        this.loadDeductions();
      });

    // Default values for creation
    if (!this.timelog.date) {
      this.timelog.date = new Date();
    }
  }

  async loadParticipantTimelog(participantId) {
    // Only when creating a new record should it pull old information
    if (!this.creating || !participantId) {
      return;
    }

    const res = await this.timelogService.list({
      participantId,
      first: 1,
    });

    const timelog = get(res, 'nodes.0');

    if (timelog) {
      this.timelog = {
        nurturer: timelog.nurturer,
        nurturerId: timelog.nurturer?.id,
        participant: timelog.participant,
        participantId: timelog.participant?.id,
        rate: timelog.rate,
        rateId: timelog.rate?.id,
        hours: timelog.hours,
        date: this.timelog?.date,
      };

      this.populatedTimelog = timelog;
    }

    this.initData();
  }

  ngOnDestroy() {
    this.centerSub.unsubscribe();
  }

  formatLabel(node) {
    const status = node.status === 'ACTIVE' ? '' : ' (inactive)';
    return {
      ...node,
      label: `${node.name}${status}`,
    };
  }

  async loadParticipants() {
    this.participantsLoading = true;

    try {
      const config: any = {
        first: 10,
        mode: 'PARTICIPANT',
        search: this.participantSearch,
      };

      // Creating logs shouldnt allow assign to active
      if (this.creating) {
        config.status = 'ACTIVE';
      }

      const res = await this.personService.list(config);
      this.participants = res.nodes.map((item) => this.formatLabel(item));
    } catch (e) {
      this.alert.error(e);
    } finally {
      this.participantsLoading = false;
    }
  }

  async loadNurturers() {
    this.nurturersLoading = true;

    try {
      const config: any = {
        first: 10,
        mode: 'NURTURER',
        search: this.nurturerSearch,
      };

      // Creating logs shouldnt allow assign to active
      if (this.creating) {
        config.status = 'ACTIVE';
      }

      const res = await this.personService.list(config);
      this.nurturers = res.nodes.map((item) => this.formatLabel(item));
    } catch (e) {
      this.alert.error(e);
    } finally {
      this.nurturersLoading = false;
    }
  }

  async loadRates() {
    this.ratesLoading = true;

    try {
      const res = await this.ratesService.list({
        first: 10,
        search: this.rateSearch,
      });

      this.rates = res.nodes;
    } catch (e) {
      this.alert.error(e);
    } finally {
      this.ratesLoading = false;
    }
  }

  async loadDeductions() {
    this.deductionsLoading = true;

    try {
      const res = await this.deductionsService.list({
        first: 10,
        search: this.deductionSearch,
      });

      this.deductions = res.nodes;
    } catch (e) {
      this.alert.error(e);
    } finally {
      this.deductionsLoading = false;
    }
  }

  async save() {
    const { timelog } = this;

    this.saving = true;

    if (timelog.id) {
      try {
        await this.timelogService.update(timelog.id, timelog);
        this.activeModal.close();
        this.alert.success(`Successfully updated timelog.`);
      } catch (e) {
        this.alert.error(e);
      }
    } else {
      try {
        const res = await this.timelogService.create(timelog);
        this.activeModal.close(res);
        this.alert.success(`Successfully recorded the timelog.`);
      } catch (e) {
        this.alert.error(e);
      }
    }

    this.saving = false;
  }

  async destroy() {
    const { timelog } = this;

    this.deleting = true;

    const res = await this.alert.fire({
      text: `Are you sure you want to delete this ${timelog.hours} hour(s) record on ${timelog.date}? This action is not reversible.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (res.value) {
      try {
        this.timelogService.delete(timelog.id);
        timelog.deleted = true;
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success('This timelog has been permanently deleted.');
    }

    this.deleting = false;
  }
}
