import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  DeductionService,
  AlertService,
  ConnectionResponse,
  ApiService,
} from '../../services';
import {
  Pagination,
  PaginationVariables,
  Deduction,
  Center,
} from '../../models';
import { DEDUCTION_MODES } from '../../constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-deduction-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="deduction.id">
        Editing {{ deduction.name }}
      </h4>
      <h4 class="modal-title" *ngIf="!deduction.id">Add Deduction</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input
        placeholder="Name"
        [(ngModel)]="deduction.name"
        class="form-control mb-3"
      />
      <input
        *ngIf="!deduction.id"
        type="number"
        placeholder="Amount"
        [(ngModel)]="deduction.amount"
        class="form-control mb-3"
      />
      <textarea
        class="form-control mt-3"
        [(ngModel)]="deduction.description"
        placeholder="Description..."
      ></textarea>

      <div
        class="btn-group btn-group-toggle mt-3"
        ngbRadioGroup
        name="mode"
        [(ngModel)]="deduction.mode"
      >
        <label
          ngbButtonLabel
          class="btn-outline-primary"
          *ngFor="let mode of modes"
        >
          <input ngbButton type="radio" [value]="mode" /> {{ mode }}
        </label>
      </div>

      <div
        *ngIf="deduction.amount && deduction.mode && center"
        class="text-center text-xl bg-secondary p-4 rounded border mt-3"
      >
        <span *ngIf="deduction.mode == 'FIXED'">{{
          deduction.amount | currency : [center.currency]
        }}</span>
        <span *ngIf="deduction.mode == 'PERCENTAGE'"
          >{{ deduction.amount | number }}%</span
        >
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-block" (click)="save()">
        Save
      </button>
    </div>
  `,
})
export class EditDeductionModalComponent implements OnInit, OnDestroy {
  @Input() deduction: Deduction = {};
  public modes: Array<string> = DEDUCTION_MODES;
  public selectedMode: string = DEDUCTION_MODES[0];
  public centerSub: Subscription;
  public center: Center;

  constructor(
    public activeModal: NgbActiveModal,
    private deductionService: DeductionService,
    private alert: AlertService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.centerSub = this.api
      .currentCenter()
      .subscribe((center) => (this.center = center));
  }

  ngOnDestroy() {
    this.centerSub.unsubscribe();
  }

  async save() {
    const { deduction } = this;

    if (deduction.id) {
      try {
        await this.deductionService.update(deduction.id, deduction);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully updated ${deduction.name}`);
    } else {
      try {
        await this.deductionService.create(deduction);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully added ${deduction.name}`);
    }

    this.activeModal.close();
  }
}
