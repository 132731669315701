import { Injectable } from '@angular/core';
import SweetAlert, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private notifierService: NotifierService
  ) { }

  success(text: string) {
    this.notifierService.show({
      type: 'success',
      message: text,
    });
  }

  error(error: any, options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    if (typeof error === 'string') {
      options.text = error;
    }

    if (error.graphQLErrors) {
      options.html = error.graphQLErrors.map(err => err.message.replace(/\n/g, '<br />'));
    }

    if (error.message) {
      options.text = error.message;
    }

    return this.fire({
      icon: 'warning',
      ...options
    });
  }

  warning(text: string, options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.fire({
      icon: 'warning',
      text,
      ...options
    });
  }

  question(text: string, options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.fire({
      text,
      ...options
    });
  }

  fire(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return SweetAlert.fire(options);
  }
}
