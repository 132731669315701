import { Injectable } from '@angular/core';
import { Payment, Pagination } from '../models';
import { ConnectionResponse } from './api.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get, pick } from 'lodash';
import * as moment from 'moment';

@Injectable()
export class PaymentService {
  constructor(private apollo: Apollo) {}

  list(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo
      .query({
        query: gql`
          query listAllPayments(
            $search: String
            $first: Int
            $last: Int
            $after: String
            $before: String
            $startDate: ISO8601Date
            $endDate: ISO8601Date
          ) {
            viewer {
              center {
                payments(
                  query: $search
                  first: $first
                  last: $last
                  after: $after
                  before: $before
                  startDate: $startDate
                  endDate: $endDate
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    amount
                    method
                    date
                    note
                    participant {
                      id
                      name
                      avatar(size: 48)
                    }
                  }
                }
              }
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.payments'));
  }

  create(data: Payment): Promise<Payment> {
    const input = pick(data, [
      'participantId',
      'method',
      'amount',
      'note',
      'date',
    ]);

    input.date = moment(input.date).format('YYYY-MM-DD');

    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($input: PaymentAttributes!) {
            paymentCreate(input: $input) {
              id
              amount
              method
              date
              note
              participant {
                id
                name
                avatar(size: 48)
              }
            }
          }
        `,
        variables: { input },
      })
      .toPromise()
      .then((res) => get(res, 'data.paymentCreate'));
  }

  update(id: string, data: Payment): Promise<Payment> {
    const input = pick(data, [
      'participantId',
      'method',
      'amount',
      'note',
      'date',
    ]);

    input.date = moment(input.date).format('YYYY-MM-DD');

    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!, $input: PaymentAttributes!) {
            paymentUpdate(paymentId: $id, input: $input) {
              id
            }
          }
        `,
        variables: {
          id,
          input,
        },
      })
      .toPromise()
      .then((res) => get(res, 'data.paymentUpdate'));
  }

  export(variables: any): Promise<string> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation (
            $query: String
            $startDate: ISO8601Date
            $endDate: ISO8601Date
          ) {
            exportPayments(
              query: $query
              startDate: $startDate
              endDate: $endDate
            ) {
              url
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.exportPayments.url'));
  }

  delete(id: string): Promise<Payment> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!) {
            paymentDelete(paymentId: $id) {
              id
            }
          }
        `,
        variables: { id },
      })
      .toPromise()
      .then((data) => get(data, 'data.paymentDelete'));
  }
}
