import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public api: ApiService) { }

  canActivate(): boolean {
    const token = this.api.getToken();

    if (!token) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }

    return true;
  }
}
