import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UserService,
  AlertService,
  ConnectionResponse,
  ApiService,
} from '../../services';
import { Pagination, PaginationVariables, User, Center } from '../../models';
import { USER_ROLES, USER_STATUSES } from '../../constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="user.id">Editing {{ user.name }}</h4>
      <h4 class="modal-title" *ngIf="!user.id">Add User</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input
        placeholder="Name"
        [(ngModel)]="user.name"
        class="form-control mb-3"
      />
      <input
        placeholder="Email"
        [(ngModel)]="user.email"
        class="form-control mb-3"
      />

      <div
        class="btn-group btn-group-toggle"
        ngbRadioGroup
        name="status"
        [(ngModel)]="user.status"
      >
        <label
          ngbButtonLabel
          class="btn-outline-primary"
          *ngFor="let status of statuses"
        >
          <input ngbButton type="radio" [value]="status" /> {{ status }}
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-block" (click)="save()">
        Save
      </button>
    </div>
  `,
})
export class EditUserModalComponent {
  @Input() user: User = {};
  public roles: Array<string> = USER_ROLES;
  public selectedRole: string = USER_ROLES[0];
  public statuses: Array<string> = USER_STATUSES;
  public selectedStatus: string = USER_STATUSES[0];

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private alert: AlertService
  ) {}

  async save() {
    const { user } = this;

    if (user.id) {
      try {
        await this.userService.update(user.id, user);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully updated ${user.name}`);
    } else {
      try {
        await this.userService.create(user);
      } catch (e) {
        this.alert.error(e);
        return;
      }

      this.alert.success(`Successfully added ${user.name}`);
    }

    this.activeModal.close();
  }
}
