import { Injectable } from '@angular/core';
import { Person, Pagination } from '../models';
import { ConnectionResponse } from './api.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get, pick } from 'lodash';

@Injectable()
export class PersonService {
  constructor(private apollo: Apollo) {}

  list(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo
      .query({
        query: gql`
          query listAllPeople(
            $search: String
            $first: Int
            $last: Int
            $after: String
            $before: String
            $mode: PersonMode
            $status: PersonStatus
            $tagList: [String!]
            $overdue: Boolean
          ) {
            viewer {
              center {
                people(
                  query: $search
                  first: $first
                  last: $last
                  after: $after
                  before: $before
                  mode: $mode
                  status: $status
                  tagList: $tagList
                  overdue: $overdue
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    name
                    mode
                    status
                    tagList
                    avatar(size: 48)
                    avatarLarge: avatar(size: 180)
                    email
                    phone
                    hours
                    balance
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.people'));
  }

  // Participant session list
  listWithSessions(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo
      .query({
        query: gql`
          query listAllPeopleWithSessions(
            $search: String
            $first: Int
            $last: Int
            $after: String
            $before: String
            $date: ISO8601Date
            $mode: PersonMode
            $status: PersonStatus
            $tagList: [String!]
          ) {
            viewer {
              center {
                people(
                  query: $search
                  first: $first
                  last: $last
                  after: $after
                  before: $before
                  mode: $mode
                  status: $status
                  tagList: $tagList
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    name
                    mode
                    status
                    avatar(size: 36)
                    avatarLarge: avatar(size: 180)
                    email
                    phone
                    hours
                    balance
                    createdAt
                    updatedAt
                    timelogs(startDate: $date, endDate: $date, first: 100) {
                      nodes {
                        id
                        hours
                        date
                        note
                        absent
                        initial
                        chargedAmount
                        rate {
                          id
                          name
                          amount
                        }
                        deduction {
                          id
                          name
                        }
                        participant {
                          id
                          name
                          avatar(size: 36)
                          avatarLarge: avatar(size: 180)
                        }
                        nurturer {
                          id
                          name
                          avatar(size: 36)
                          avatarLarge: avatar(size: 180)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.people'));
  }

  get(id: string): Promise<Person> {
    return this.apollo
      .query({
        query: gql`
          query getPerson($id: ID!) {
            viewer {
              center {
                people(id: $id) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    name
                    mode
                    status
                    tagList
                    avatar(size: 36)
                    avatarLarge: avatar(size: 180)
                    email
                    phone
                    hours
                    balance
                    sessionStats {
                      name
                      totalSessions
                      totalHours
                      personId
                      firstSession
                      lastSession
                    }
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.people.nodes.0'));
  }

  create(data: Person): Promise<Person> {
    const input = pick(data, [
      'name',
      'tagList',
      'status',
      'mode',
      'birthday',
      'phone',
      'email',
    ]);

    if (typeof input.tagList === 'string') {
      input.tagList = input.tagList.split(',');
    }

    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($input: PersonCreateAttributes!) {
            personCreate(input: $input) {
              id
              name
            }
          }
        `,
        variables: { input },
      })
      .toPromise()
      .then((res) => get(res, 'data.personCreate'));
  }

  update(id: string, data: Person): Promise<Person> {
    const input = pick(data, [
      'name',
      'tagList',
      'status',
      'birthday',
      'phone',
      'email',
    ]);

    if (typeof input.tagList === 'string') {
      input.tagList = input.tagList.split(',');
    }

    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!, $input: PersonUpdateAttributes!) {
            personUpdate(personId: $id, input: $input) {
              id
              name
            }
          }
        `,
        variables: {
          id,
          input,
        },
      })
      .toPromise()
      .then((res) => get(res, 'data.personUpdate'));
  }

  export(variables: any): Promise<string> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation (
            $query: String
            $mode: PersonMode
            $status: PersonStatus
            $tagList: [String!]
            $overdue: Boolean
          ) {
            exportPeople(
              query: $query
              mode: $mode
              status: $status
              tagList: $tagList
              overdue: $overdue
            ) {
              url
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((res) => get(res, 'data.exportPeople.url'));
  }

  sendSummary(data: Person): Promise<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!) {
            personSendSummary(personId: $id)
          }
        `,
        variables: { id: data.id },
      })
      .toPromise()
      .then((res) => get(res, 'data.personSendSummary'));
  }
}
