import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationComponent } from './pagination/pagination.component';
import { StatisticComponent } from './statistic/statistic.component';
import { OverdueListComponent } from './overdue-list/overdue-list.component';
import { TimelogChartComponent } from './timelog-chart/timelog-chart.component';
import { RevenueChartComponent } from './revenue-chart/revenue-chart.component';
import { PaymentChartComponent } from './payment-chart/payment-chart.component';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    BsDatepickerModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    PaginationComponent,
    StatisticComponent,
    TimelogChartComponent,
    RevenueChartComponent,
    PaymentChartComponent,
    OverdueListComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    PaginationComponent,
    StatisticComponent,
    TimelogChartComponent,
    RevenueChartComponent,
    PaymentChartComponent,
    OverdueListComponent,
  ]
})
export class ComponentsModule { }
