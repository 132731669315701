import { Injectable } from '@angular/core';
import { Timelog, Pagination } from '../models';
import { ConnectionResponse } from './api.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get, pick } from 'lodash';
import * as moment from 'moment';

@Injectable()
export class TimelogService {
  constructor(private apollo: Apollo) {}

  list(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo
      .query({
        query: gql`
          query listAllTimelogs(
            $nurturerId: ID
            $participantId: ID
            $search: String
            $first: Int
            $last: Int
            $after: String
            $before: String
            $startDate: ISO8601Date
            $endDate: ISO8601Date
          ) {
            viewer {
              center {
                timelogs(
                  participantId: $participantId
                  nurturerId: $nurturerId
                  query: $search
                  first: $first
                  last: $last
                  after: $after
                  before: $before
                  startDate: $startDate
                  endDate: $endDate
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    hours
                    date
                    absent
                    initial
                    note
                    chargedAmount
                    rate {
                      id
                      amount
                      description
                      name
                    }
                    deduction {
                      id
                      name
                    }
                    participant {
                      id
                      name
                      avatar(size: 36)
                      avatarLarge: avatar(size: 180)
                    }
                    nurturer {
                      id
                      name
                      avatar(size: 36)
                      avatarLarge: avatar(size: 180)
                    }
                  }
                }
              }
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.timelogs'));
  }

  create(data: Timelog): Promise<Timelog> {
    const input = pick(data, [
      'absent',
      'date',
      'deductionId',
      'hours',
      'initial',
      'note',
      'nurturerId',
      'participantId',
      'rateId',
    ]);

    input.date = moment(input.date).format('YYYY-MM-DD');

    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($input: TimelogAttributes!) {
            timelogCreate(input: $input) {
              id
              hours
              date
              note
              chargedAmount
              absent
              initial
              rate {
                id
                amount
                description
                name
              }
              deduction {
                id
                name
              }
              participant {
                id
                name
                avatar(size: 36)
                avatarLarge: avatar(size: 180)
              }
              nurturer {
                id
                name
                avatar(size: 36)
                avatarLarge: avatar(size: 180)
              }
            }
          }
        `,
        variables: { input },
      })
      .toPromise()
      .then((res) => get(res, 'data.timelogCreate'));
  }

  update(id: string, data: Timelog): Promise<Timelog> {
    const input = pick(data, [
      'absent',
      'date',
      'deductionId',
      'hours',
      'initial',
      'note',
      'nurturerId',
      'participantId',
      'rateId',
    ]);

    input.date = moment(input.date).format('YYYY-MM-DD');

    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!, $input: TimelogAttributes!) {
            timelogUpdate(timelogId: $id, input: $input) {
              id
              hours
              date
              note
              chargedAmount
              absent
              initial
              rate {
                id
                amount
                description
                name
              }
              deduction {
                id
                name
              }
              participant {
                id
                name
                avatar(size: 36)
                avatarLarge: avatar(size: 180)
              }
              nurturer {
                id
                name
                avatar(size: 36)
                avatarLarge: avatar(size: 180)
              }
            }
          }
        `,
        variables: {
          id,
          input,
        },
      })
      .toPromise()
      .then((res) => get(res, 'data.timelogUpdate'));
  }

  export(variables: any): Promise<string> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation (
            $query: String
            $nurturerId: ID
            $participantId: ID
            $startDate: ISO8601Date
            $endDate: ISO8601Date
          ) {
            exportTimelogs(
              query: $query
              nurturerId: $nurturerId
              participantId: $participantId
              startDate: $startDate
              endDate: $endDate
            ) {
              url
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.exportTimelogs.url'));
  }

  delete(id: string): Promise<Timelog> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!) {
            timelogDelete(timelogId: $id) {
              id
            }
          }
        `,
        variables: { id },
      })
      .toPromise()
      .then((data) => get(data, 'data.timelogDelete'));
  }
}
