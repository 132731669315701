export class Center {
  id?: string;
  active?: boolean;
  name?: string;
  address?: string;
  websiteUrl?: string;
  customEmailMessage?: string;
  phone?: string;
  billingEmail?: string;
  email?: string;
  currency?: string;
  timezone?: string;
}
