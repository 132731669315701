// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { get } from 'lodash';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
      private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.authTokenKey);

    // Adds the session to the actual request
    const options: any = {
      setHeaders: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    };

    // Makes sure the multiplexing for GraphQL works
    if (Array.isArray(request.body)) {
      options.body = { _json: request.body };
    }

    // tslint:disable-next-line:no-debugger
    request = request.clone(options);

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (Array.isArray(event.body)) {
              const viewer = get(event, 'body.0.data.viewer');
              if (viewer === null) {
                this.router.navigateByUrl('/auth/login');
              }
            }
          }
        },
        error => {}
      )
    );
  }
}
