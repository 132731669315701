import { Component, OnInit, Input, OnDestroy, Output, ElementRef, ViewChild } from '@angular/core';
import { StatisticsService, ApiService } from '../../services';
import { get } from 'lodash';
import Chart from 'chart.js';
import { COLORS, FONTS } from '../../constants';
import * as moment from 'moment';

@Component({
  selector: 'app-timelog-chart',
  templateUrl: './timelog-chart.component.html',
  styleUrls: ['./timelog-chart.component.scss']
})
export class TimelogChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) el: ElementRef;
  @Input() personId: string; // Determines if center or person is used

  public chart: Chart;
  public selectedDates: Date[];

  constructor(
    private stats: StatisticsService,
    private api: ApiService
  ) {
    this.selectedDates = [moment().subtract(1, 'month').toDate(), new Date()];
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
  }

  async loadData() {
    const options: any = {
      granularity: 'DAILY',
      fields: ['hours_sum'],
      range: {
        start: this.selectedDates[0],
        end: this.selectedDates[1],
      }
    };

    if (this.personId) { options.personId = this.personId; }

    const fn = this.personId ? 'personTimelogs' : 'centerTimelogs';
    const res: Array<any> = (await this.stats[fn](options))
      .sort((a, b) => (a.date > b.date) ? 1 : -1);

    this.updateChart(res);
  }

  updateChart(data: Array<any>) {
    if (! this.chart) {
      this.chart = new Chart(this.el.nativeElement, {
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          color: COLORS.gray[600],
          fontColor: COLORS.gray[600],
          fontFamily: FONTS.base,
          fontSize: 13,
          legend: {
            display: true,
            position: 'bottom',
          },
          hover: {
            mode: 'index'
          },
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                tooltipFormat: 'll'
              },
              scaleLabel: {
                  display:     true,
                  labelString: 'Date'
              }
            }],
            yAxes: [{
              type: 'linear',
              display: true,
              position: 'left',
              id: 'sum',

              // grid line settings
              gridLines: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },

              ticks: {
                beginAtZero: true
              },
            }],
          },
          tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
          },
          elements: {
            line: {
              tension: .1,
              borderColor: COLORS.theme['primary'],
              backgroundColor: COLORS.transparent,
              borderCapStyle: 'rounded'
            }
          },
          layout: {
            padding: {
              left: 0,
              right: 10,
              top: 5,
              bottom: 10
            }
          }
        }
      });
    }

    this.chart.data = {
      labels: data.map(item => item.date),
      datasets: [{
        label: 'Total',
        yAxisID: 'sum',
        backgroundColor: COLORS.theme['primary'],
        data: data.map(item => ({ x: item.date, y: item.hours_sum }))
      }]
    };

    this.chart.update();
  }
}
