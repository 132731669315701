import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { take, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertService } from '../services';

@Injectable()
export class PayGuard implements CanActivate {
  constructor(
    private router: Router,
    public api: ApiService,
    public alert: AlertService
  ) {}

  canActivate(): Observable<boolean> {
    return this.api.currentCenter().pipe(
      take(1),
      map(center => !!center?.active),
      tap(active => {
        if (!active) {
          this.router.navigateByUrl('/settings/billing');
          this.alert.warning('Your subscription has ended, please ensure your subscription is active before continuing.');
        }
      })
    );
  }
}
