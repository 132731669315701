import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  PaymentService,
  AlertService,
  ConnectionResponse,
  ApiService,
  PersonService,
} from '../../services';
import {
  Pagination,
  PaginationVariables,
  Center,
  Payment,
  Person,
} from '../../models';
import { Subscription, Observable, Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce, get } from 'lodash';
import { PAYMENT_METHODS } from '../../constants';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-payment-editor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="payment.id">Edit Payment</h4>
      <h4 class="modal-title" *ngIf="!payment.id">Add Payment</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-select
        class="mb-3"
        placeholder="Participant"
        bindLabel="name"
        bindValue="id"
        [items]="participants"
        [(ngModel)]="payment.participantId"
        [typeahead]="participantInput$"
        [loading]="loadingParticipants"
      ></ng-select>

      <input
        type="text"
        placeholder="Date"
        class="inline form-control input-sm mb-3"
        [(ngModel)]="payment.date"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
        bsDatepicker
      />

      <input
        type="number"
        placeholder="Amount"
        [(ngModel)]="payment.amount"
        class="form-control mb-3"
      />

      <div
        class="btn-group btn-group-toggle"
        ngbRadioGroup
        [(ngModel)]="payment.method"
      >
        <label
          ngbButtonLabel
          class="btn-outline-primary"
          *ngFor="let method of methods"
        >
          <input ngbButton type="radio" [value]="method" /> {{ method }}
        </label>
      </div>

      <textarea
        class="form-control mt-3"
        [(ngModel)]="payment.note"
        placeholder="Internal note..."
      ></textarea>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        [class.btn-loading]="saving"
        class="btn btn-primary btn-block"
        (click)="save()"
      >
        Save
      </button>
    </div>
  `,
})
export class EditPaymentModalComponent implements OnInit, OnDestroy {
  @Input() payment: Payment = {};
  public saving: Boolean = false;
  public participants: Array<Person> = [];
  public methods: Array<string> = PAYMENT_METHODS;
  public participantInput$: Subject<string> = new Subject<string>();
  public loadingParticipants = false;
  public center: Center;
  private centerSub: Subscription;
  public search: string;

  constructor(
    public activeModal: NgbActiveModal,
    private paymentService: PaymentService,
    private personService: PersonService,
    private alert: AlertService,
    private api: ApiService
  ) {
    this.centerSub = this.api
      .currentCenter()
      .subscribe((center) => (this.center = center));
  }

  ngOnInit() {
    this.loadParticipants();

    this.participantInput$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.search = value;
        this.loadParticipants();
      });

    // Default values for creation
    if (!this.payment.date) {
      this.payment.date = new Date();
    }
  }

  ngOnDestroy() {
    this.centerSub.unsubscribe();
  }

  async loadParticipants() {
    let res;
    this.loadingParticipants = true;

    try {
      res = await this.personService.list({
        first: 10,
        mode: 'PARTICIPANT',
        status: 'ACTIVE',
        search: this.search,
      });
    } catch (e) {
      this.alert.error(e);
      this.loadingParticipants = false;
      return;
    }

    this.loadingParticipants = false;
    this.participants = res.nodes;
  }

  async save() {
    const { payment } = this;

    this.saving = true;

    if (payment.id) {
      try {
        await this.paymentService.update(payment.id, payment);
        this.activeModal.close();
        this.alert.success(`Successfully updated payment.`);
      } catch (e) {
        this.alert.error(e);
      }
    } else {
      try {
        const res = await this.paymentService.create(payment);
        this.activeModal.close(res);
        this.alert.success(`Successfully recorded the payment.`);
      } catch (e) {
        this.alert.error(e);
      }
    }

    this.saving = false;
  }
}
