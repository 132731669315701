import { Injectable } from '@angular/core';
import { Center, Pagination } from '../models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { ApiService } from './api.service';

@Injectable()
export class CenterService {
  constructor(private apollo: Apollo, private api: ApiService) {}

  update(center: Center): Promise<Center> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation updateCenter($center: CenterAttributes!) {
            centerUpdate(input: $center) {
              id
              active
              name
              address
              websiteUrl
              customEmailMessage
              phone
              billingEmail
              email
              currency
              timezone
            }
          }
        `,
        variables: { center },
      })
      .toPromise()
      .then((data) => {
        const centerRes = get(data, 'data.centerUpdate');

        this.api.storeCenter(centerRes);

        return centerRes;
      });
  }
}
