import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { StatisticsService, ApiService } from '../../services';
import { get } from 'lodash';
import Chart from 'chart.js';
import * as moment from 'moment';
import { COLORS, FONTS } from '../../constants';

@Component({
  selector: 'app-payment-chart',
  templateUrl: './payment-chart.component.html',
  styleUrls: ['./payment-chart.component.scss'],
})
export class PaymentChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) el: ElementRef;
  @Input() personId: string; // Determines if center or person is used

  public chart: Chart;
  public selectedDates: Date[];

  constructor(private stats: StatisticsService, private api: ApiService) {
    this.selectedDates = [moment().subtract(1, 'month').toDate(), new Date()];
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {}

  async loadData() {
    const options: any = {
      granularity: 'DAILY',
      fields: ['amount_sum'],
      range: {
        start: this.selectedDates[0],
        end: this.selectedDates[1],
      },
    };

    if (this.personId) {
      options.personId = this.personId;
    }

    const fn = this.personId ? 'personPayments' : 'centerPayments';
    const res: Array<any> = (await this.stats[fn](options)).sort((a, b) =>
      a.date > b.date ? 1 : -1
    );

    this.updateChart(res);
  }

  updateChart(data: Array<any>) {
    if (!this.chart) {
      this.chart = new Chart(this.el.nativeElement, {
        type: 'bar',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          color: COLORS.gray[600],
          fontColor: COLORS.gray[600],
          fontFamily: FONTS.base,
          fontSize: 13,
          legend: {
            display: false,
          },
          elements: {
            rectangle: {
              backgroundColor: COLORS.theme['warning'],
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  tooltipFormat: 'll',
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    if (!(value % 10)) {
                      // return '$' + value + 'k'
                      return value;
                    }
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (item, out) {
                const label = out.datasets[item.datasetIndex].label || '';
                const yLabel = item.yLabel;
                let content = '';
                if (out.datasets.length > 1) {
                  content += label;
                }
                content += yLabel;
                return content;
              },
            },
          },
        },
      });
    }

    this.chart.data = {
      labels: data.map((item) => item.date),
      datasets: [
        {
          label: 'Payments',
          data: data.map((item) => ({ x: item.date, y: item.amount_sum })),
        },
      ],
    };

    this.chart.update();
  }
}
