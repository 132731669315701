import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { StatisticsService, ApiService } from '../../services';
import { get } from 'lodash';
import { GRANULARITIES } from '../../constants';
import { Center } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistic-widget',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() label: string;
  @Input() mode: string; // 'payment' or 'timelog'
  @Input() field: string; // the field to do calculations on
  @Input() personId: string; // Determines if center or person is used

  public granularities: Array<string> = GRANULARITIES;
  public granularity = 'WEEKLY';
  public value = 0;
  public changeValue: number;
  public center: Center;
  public centerSub: Subscription;

  constructor(
    private stats: StatisticsService,
    private api: ApiService
  ) {
    this.centerSub = this.api.currentCenter().subscribe(center => this.center = center);
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.centerSub.unsubscribe();
  }

  loadData() {
    if (this.mode === 'timelog') {
      this.loadTimelogs();
    } else {
      this.loadPayments();
    }
  }

  async loadPayments() {
    const options: any = {
      granularity: this.granularity,
      fields: [this.field],
      last: 2
    };

    if (this.personId) { options.personId = this.personId; }

    const fn = this.personId ? 'personPayments' : 'centerPayments';
    const data = await this.stats[fn](options);
    this.process(data);
  }

  async loadTimelogs() {
    const options: any = {
      granularity: this.granularity,
      fields: [this.field],
      last: 2
    };

    if (this.personId) { options.personId = this.personId; }

    const fn = this.personId ? 'personTimelogs' : 'centerTimelogs';
    const data = await this.stats[fn](options);

    this.process(data);
  }

  changeGranularity(granularity) {
    this.granularity = granularity;
    this.loadData();
  }

  process(data: any) {
    // Make sure the most recent date is first
    data = data.sort((a, b) => (a.date < b.date) ? 1 : -1);

    const first = get(data, [0, this.field]);
    const last = get(data, [1, this.field]);
    this.value = first || 0;
    this.changeValue = last ? this.calculateChange(first, last) : 0;
  }

  calculateChange(first, last): number {
    return (last - first) / first * 100;
  }
}
