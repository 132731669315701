import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { Pagination } from '../../models';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() paginator: Pagination;
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();

  nextPage() {
    this.next.emit();
  }

  previousPage() {
    this.previous.emit();
  }

  constructor() {}
}
