import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Observable, from } from 'rxjs';
import { AlertService } from '../services';

@Injectable()
export class SessionGuard implements CanActivate {
  constructor(
    private router: Router,
    public api: ApiService,
    public alert: AlertService
  ) {}

  canActivate(): Observable<boolean> {
    const res = this.api.refreshSession().then(() => true);
    return from(res);
  }
}
