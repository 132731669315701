import { Injectable } from '@angular/core';
import { Center, Pagination } from '../models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { ApiService, ConnectionResponse } from './api.service';

@Injectable()
export class BillingService {
  constructor(
    private apollo: Apollo,
    private api: ApiService,
  ) { }

  updatePaymentSource(variables: any = {}): Promise<boolean> {
    return this.apollo.mutate({
      mutation: gql`mutation($token: String!) {
        billingUpdatePaymentSource(
          token: $token
        ) {
          success
        }
      }
      `,
      variables
    })
    .toPromise()
    .then(data => get(data, 'data.updatePaymentSource.success'));
  }

  invoices(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo.query({
      query: gql`
      query getAll($first: Int, $last: Int, $after: String, $before: String) {
        viewer {
          center {
            invoices(first: $first, last: $last, after: $after, before: $before) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
                startCursor
              }
              nodes{
                id
                amountDue
                amountPaid
                amountRemaining
                downloadUrl
                periodEnd
                periodStart
                status
                url
              }
            }
          }
        }
      }
      `,
      variables
    })
    .toPromise()
    .then(data => get(data, 'data.viewer.center.invoices'));
  }

  stopSubscription() {
    return this.apollo.mutate({
      mutation: gql`mutation  {
        billingStopSubscription {
          success
        }
      }
      `,
      variables: {}
    })
    .toPromise()
    .then(data => get(data, 'data.stopSubscription.success'));
  }

  startSubscription() {
    return this.apollo.mutate({
      mutation: gql`mutation  {
        billingStartSubscription{
          success
        }
      }
      `,
      variables: {}
    }).toPromise();
  }
}
