import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditTimelogModalComponent } from './edit-timelog/edit-timelog.modal';
import { EditPaymentModalComponent } from './edit-payment/edit-payment.modal';
import { EditUserModalComponent } from './edit-user/edit-user.modal';
import { EditUserPasswordModalComponent } from './edit-user-password/edit-user-password.modal';
import { EditPersonModalComponent } from './edit-person/edit-person.modal';
import { EditDeductionModalComponent } from './edit-deduction/edit-deduction.modal';
import { EditRateModalComponent } from './edit-rate/edit-rate.modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    TagInputModule,
    NgSelectModule,
    BsDatepickerModule,
    AlertModule,
  ],
  declarations: [
    EditTimelogModalComponent,
    EditPaymentModalComponent,
    EditPersonModalComponent,
    EditDeductionModalComponent,
    EditRateModalComponent,
    EditUserModalComponent,
    EditUserPasswordModalComponent,
  ],
  exports: [
    EditTimelogModalComponent,
    EditPaymentModalComponent,
    EditPersonModalComponent,
    EditDeductionModalComponent,
    EditRateModalComponent,
    EditUserModalComponent,
    EditUserPasswordModalComponent,
  ],
})
export class ModalsModule {}
