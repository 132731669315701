import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { take, tap, map } from 'rxjs/operators';
import { User, Center, PaginationPageInfo } from '../models';
import { environment } from '../../environments/environment';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get } from 'lodash';
import * as jwt from 'jwt-decode';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import * as CenterActions from '../actions/center.actions';

export interface ConnectionResponse {
  pageInfo: PaginationPageInfo;
  nodes: Array<any>;
}

@Injectable()
export class ApiService {
  constructor(
    private router: Router,
    private apollo: Apollo,
    private store: Store<AppState>
  ) {}

  // Authentication/Authorization
  login(center: string, email: string, password: string): Promise<User> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($center: String!, $email: String!, $password: String!) {
            authLogin(center: $center, email: $email, password: $password) {
              token
              user {
                id
                name
                center {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: { center, email, password },
      })
      .toPromise()
      .then((data) => {
        const res = get(data, 'data.authLogin');
        const user = get(data, 'data.authLogin.user');
        const c = get(data, 'data.authLogin.user.center');

        this.store.dispatch(UserActions.update({ user }));
        this.store.dispatch(CenterActions.update({ center: c }));

        // Apply for the session
        localStorage.setItem(environment.authTokenKey, res.token);

        return res.user;
      });
  }

  clearSession(): void {
    localStorage.removeItem(environment.authTokenKey);
  }

  getToken(): string {
    const now = Date.now().valueOf() / 1000;
    const token = localStorage.getItem(environment.authTokenKey);

    if (!token) {
      return;
    }

    // Remove and nullify if expired
    if (jwt(token).exp < now) {
      localStorage.removeItem(environment.authTokenKey);
      return;
    }

    return token;
  }

  storeCenter(center: Center) {
    this.store.dispatch(CenterActions.update({ center }));
  }

  storeUser(user: User) {
    this.store.dispatch(UserActions.update({ user }));
  }

  register(user: User, center: Center): Promise<User> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation (
            $center: CenterAttributes!
            $name: String!
            $email: String!
            $password: String!
          ) {
            authRegister(
              name: $name
              email: $email
              password: $password
              center: $center
            ) {
              user {
                id
                name
                center {
                  id
                  name
                  slug
                }
              }
            }
          }
        `,
        variables: {
          name: user.name,
          email: user.email,
          password: user.password,
          center,
        },
      })
      .toPromise()
      .then((data) => get(data, 'data.authRegister.user'));
  }

  requestPassword(center: string, email: string): Promise<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($center: String!, $email: String!) {
            authResetPassword(center: $center, email: $email) {
              success
            }
          }
        `,
        variables: { center, email },
      })
      .toPromise()
      .then((data) => get(data, 'data.authResetPassword.success'));
  }

  refreshSession() {
    return this.apollo
      .query({
        query: gql`
          {
            viewer {
              id
              name
              email
              avatar(size: 36)
              roles
              center {
                id
                active
                name
                address
                websiteUrl
                customEmailMessage
                phone
                billingEmail
                email
                currency
                timezone
              }
            }
          }
        `,
      })
      .toPromise()
      .then((data) => {
        const user = get(data, 'data.viewer');
        const center = get(data, 'data.viewer.center');

        if (!user || !center) {
          this.router.navigateByUrl('/auth/login');
          return;
        }

        this.storeUser(user);
        this.storeCenter(center);
      })
      .catch((err) => {
        console.error(err);
        this.router.navigateByUrl('/auth/login');
      });
  }

  currentCenter(): Observable<Center> {
    return this.store.select((state: any) => state.appState.center);
  }

  currentUser(): Observable<User> {
    return this.store.select((state: any) => state.appState.user);
  }

  verifyUser(token: string): Promise<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($token: String!) {
            authVerifyEmail(token: $token) {
              success
            }
          }
        `,
        variables: { token },
      })
      .toPromise()
      .then((data) => get(data, 'data.authVerifyEmail.success'));
  }

  updatePassword(token: string, password: string): Promise<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($token: String!, $password: String!) {
            authUpdatePassword(token: $token, password: $password) {
              success
            }
          }
        `,
        variables: { token, password },
      })
      .toPromise()
      .then((data) => get(data, 'data.authUpdatePassword.success'));
  }
}
