import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import * as CenterActions from '../actions/center.actions';
import { User, Center } from '../models';

export interface AppState {
  user?: User;
  center?: Center;
}

export const initialState: AppState = {
  center: null,
  user: null
};

const reducer = createReducer(
  initialState,
  on(UserActions.update, (state, { user }) => ({ ...state, user })),
  on(CenterActions.update, (state, { center }) => ({ ...state, center })),
);

export function appReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action);
}
