export class Deduction {
  id?: string;
  amount?: number;
  description?: string;
  mode?: string;
  name?: string;
  discarded?: boolean;

  // For UI purposes this is set when its deleted
  deleted?: boolean;
}
