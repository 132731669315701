import { Injectable } from '@angular/core';
import { Deduction, Pagination } from '../models';
import { ConnectionResponse } from './api.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { get, pick } from 'lodash';

@Injectable()
export class DeductionService {
  constructor(private apollo: Apollo) {}

  list(variables: any = {}): Promise<ConnectionResponse> {
    return this.apollo
      .query({
        query: gql`
          query listAllDeductions(
            $search: String
            $first: Int
            $last: Int
            $after: String
            $before: String
            $discarded: Boolean
          ) {
            viewer {
              center {
                deductions(
                  query: $search
                  first: $first
                  last: $last
                  after: $after
                  before: $before
                  discarded: $discarded
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                  nodes {
                    id
                    amount
                    archived
                    description
                    mode
                    name
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        `,
        variables,
      })
      .toPromise()
      .then((data) => get(data, 'data.viewer.center.deductions'));
  }

  create(data: Deduction): Promise<Deduction> {
    const input = pick(data, ['amount', 'description', 'mode', 'name']);
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($input: DeductionAttributes!) {
            deductionCreate(input: $input) {
              id
            }
          }
        `,
        variables: { input },
      })
      .toPromise()
      .then((res) => get(res, 'data.deductionCreate'));
  }

  update(id: string, data: Deduction): Promise<Deduction> {
    const input = pick(data, ['amount', 'description', 'mode', 'name']);
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!, $input: DeductionAttributes!) {
            deductionUpdate(deductionId: $id, input: $input) {
              id
            }
          }
        `,
        variables: {
          id,
          input,
        },
      })
      .toPromise()
      .then((res) => get(res, 'data.deductionUpdate'));
  }

  archive(id: string): Promise<Deduction> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deductionArchive(deductionId: $id) {
              id
            }
          }
        `,
        variables: { id },
      })
      .toPromise()
      .then((res) => get(res, 'data.deductionArchive'));
  }

  restore(id: string): Promise<Deduction> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deductionRestore(deductionId: $id) {
              id
            }
          }
        `,
        variables: { id },
      })
      .toPromise()
      .then((res) => get(res, 'data.deductionRestore'));
  }
}
