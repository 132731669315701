// People status from API
export const PEOPLE_STATUSES: Array<string> = ['ACTIVE', 'INACTIVE'];

// People modes from API
export const PEOPLE_MODES: Array<string> = ['PARTICIPANT', 'NURTURER'];

// Methods for payment from API
export const PAYMENT_METHODS: Array<string> = ['CASH', 'CHECK', 'CREDIT', 'DEBIT'];

// Types of deductions
export const DEDUCTION_MODES: Array<string> = ['PERCENTAGE', 'FIXED'];

// Granularity for statistics
export const GRANULARITIES: Array<string> = ['DAILY', 'WEEKLY', 'MONTHLY'];

// Roles for center users
export const USER_ROLES: Array<string> = ['ADMIN'];

// Status for center users
export const USER_STATUSES: Array<string> = ['PENDING', 'ACTIVE', 'INACTIVE'];

export const COLORS = {
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529'
  },
  theme: {
    'default': '#172b4d',
    'primary': '#5e72e4',
    'secondary': '#f4f5f7',
    'info': '#11cdef',
    'success': '#2dce89',
    'danger': '#f5365c',
    'warning': '#fb6340'
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent',
};

export const FONTS = {
  base: 'Open Sans'
};

// These are from Ruby: https://api.rubyonrails.org/classes/ActiveSupport/TimeZone.html
export const TIMEZONES: Array<string> = [
  'Pacific/Midway',
  'Pacific/Pago_Pago',
  'Pacific/Honolulu',
  'America/Juneau',
  'America/Los_Angeles',
  'America/Tijuana',
  'America/Denver',
  'America/Phoenix',
  'America/Chihuahua',
  'America/Mazatlan',
  'America/Chicago',
  'America/Regina',
  'America/Mexico_City',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Guatemala',
  'America/New_York',
  'America/Indiana/Indianapolis',
  'America/Bogota',
  'America/Lima',
  'America/Lima',
  'America/Halifax',
  'America/Caracas',
  'America/La_Paz',
  'America/Santiago',
  'America/St_Johns',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'America/Montevideo',
  'America/Guyana',
  'America/Puerto_Rico',
  'America/Godthab',
  'Atlantic/South_Georgia',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Europe/Dublin',
  'Europe/London',
  'Europe/Lisbon',
  'Europe/London',
  'Africa/Casablanca',
  'Africa/Monrovia',
  'Etc/UTC',
  'Europe/Belgrade',
  'Europe/Bratislava',
  'Europe/Budapest',
  'Europe/Ljubljana',
  'Europe/Prague',
  'Europe/Sarajevo',
  'Europe/Skopje',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Brussels',
  'Europe/Copenhagen',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Amsterdam',
  'Europe/Berlin',
  'Europe/Zurich',
  'Europe/Zurich',
  'Europe/Rome',
  'Europe/Stockholm',
  'Europe/Vienna',
  'Africa/Algiers',
  'Europe/Bucharest',
  'Africa/Cairo',
  'Europe/Helsinki',
  'Europe/Kiev',
  'Europe/Riga',
  'Europe/Sofia',
  'Europe/Tallinn',
  'Europe/Vilnius',
  'Europe/Athens',
  'Europe/Istanbul',
  'Europe/Minsk',
  'Asia/Jerusalem',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Moscow',
  'Europe/Volgograd',
  'Europe/Samara',
  'Asia/Kuwait',
  'Asia/Riyadh',
  'Africa/Nairobi',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Asia/Muscat',
  'Asia/Muscat',
  'Asia/Baku',
  'Asia/Tbilisi',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Karachi',
  'Asia/Tashkent',
  'Asia/Kolkata',
  'Asia/Kolkata',
  'Asia/Kolkata',
  'Asia/Kolkata',
  'Asia/Kathmandu',
  'Asia/Dhaka',
  'Asia/Dhaka',
  'Asia/Colombo',
  'Asia/Almaty',
  'Asia/Novosibirsk',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Bangkok',
  'Asia/Jakarta',
  'Asia/Krasnoyarsk',
  'Asia/Shanghai',
  'Asia/Chongqing',
  'Asia/Hong_Kong',
  'Asia/Urumqi',
  'Asia/Kuala_Lumpur',
  'Asia/Singapore',
  'Asia/Taipei',
  'Australia/Perth',
  'Asia/Irkutsk',
  'Asia/Ulaanbaatar',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Tokyo',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Australia/Darwin',
  'Australia/Adelaide',
  'Australia/Melbourne',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Asia/Vladivostok',
  'Pacific/Guam',
  'Pacific/Port_Moresby',
  'Asia/Magadan',
  'Asia/Srednekolymsk',
  'Pacific/Guadalcanal',
  'Pacific/Noumea',
  'Pacific/Fiji',
  'Asia/Kamchatka',
  'Pacific/Majuro',
  'Pacific/Auckland',
  'Pacific/Auckland',
  'Pacific/Tongatapu',
  'Pacific/Fakaofo',
  'Pacific/Chatham',
  'Pacific/Apia',
  'Etc/GMT+12',
];

// Currencies are based off the ISO codes
export const CURRENCIES: Array<string> = [
  'AFA',
  'ALL',
  'DZD',
  'AOR',
  'ARS',
  'AMD',
  'AWG',
  'AUD',
  'AZN',
  'BSD',
  'BHD',
  'BDT',
  'BBD',
  'BYN',
  'BZD',
  'BMD',
  'BTN',
  'BOB',
  'BWP',
  'BRL',
  'GBP',
  'BND',
  'BGN',
  'BIF',
  'KHR',
  'CAD',
  'CVE',
  'KYD',
  'XOF',
  'XAF',
  'XPF',
  'CLP',
  'CNY',
  'COP',
  'KMF',
  'CDF',
  'CRC',
  'HRK',
  'CUP',
  'CZK',
  'DKK',
  'DJF',
  'DOP',
  'XCD',
  'EGP',
  'SVC',
  'ERN',
  'EEK',
  'ETB',
  'EUR',
  'FKP',
  'FJD',
  'GMD',
  'GEL',
  'GHS',
  'GIP',
  'XAU',
  'XFO',
  'GTQ',
  'GNF',
  'GYD',
  'HTG',
  'HNL',
  'HKD',
  'HUF',
  'ISK',
  'XDR',
  'INR',
  'IDR',
  'IRR',
  'IQD',
  'ILS',
  'JMD',
  'JPY',
  'JOD',
  'KZT',
  'KES',
  'KWD',
  'KGS',
  'LAK',
  'LVL',
  'LBP',
  'LSL',
  'LRD',
  'LYD',
  'LTL',
  'MOP',
  'MKD',
  'MGA',
  'MWK',
  'MYR',
  'MVR',
  'MRO',
  'MUR',
  'MXN',
  'MDL',
  'MNT',
  'MAD',
  'MZN',
  'MMK',
  'NAD',
  'NPR',
  'ANG',
  'NZD',
  'NIO',
  'NGN',
  'KPW',
  'NOK',
  'OMR',
  'PKR',
  'XPD',
  'PAB',
  'PGK',
  'PYG',
  'PEN',
  'PHP',
  'XPT',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'RWF',
  'SHP',
  'WST',
  'STD',
  'SAR',
  'RSD',
  'SCR',
  'SLL',
  'XAG',
  'SGD',
  'SBD',
  'SOS',
  'ZAR',
  'KRW',
  'LKR',
  'SDG',
  'SRD',
  'SZL',
  'SEK',
  'CHF',
  'SYP',
  'TWD',
  'TJS',
  'TZS',
  'THB',
  'TOP',
  'TTD',
  'TND',
  'TRY',
  'TMT',
  'AED',
  'UGX',
  'XFU',
  'UAH',
  'UYU',
  'USD',
  'UZS',
  'VUV',
  'VEF',
  'VND',
  'YER',
  'ZMK',
  'ZWL',
];
