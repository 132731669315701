export interface PaginationVariables {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
}

export interface PaginationPageInfo {
  endCursor?: string;
  startCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
}

export class Pagination {
  private perPage: number;
  private pageInfo: PaginationPageInfo = {};

  constructor(perPage?: number) {
    this.perPage = perPage || 10;
  }

  updatePageInfo(pageInfo: PaginationPageInfo) {
    this.pageInfo = pageInfo;
  }

  visible(): boolean {
    return this.hasNextPage() || this.hasPreviousPage();
  }

  hasNextPage(): boolean {
    return this.pageInfo.hasNextPage;
  }

  hasPreviousPage(): boolean {
    return this.pageInfo.hasPreviousPage;
  }

  nextVariables(): PaginationVariables {
    return {
      first: this.perPage,
      after: this.pageInfo.endCursor
    };
  }

  firstVariables(): PaginationVariables {
    return {
      first: this.perPage
    };
  }

  previousVariables(): PaginationVariables {
    return {
      last: this.perPage,
      before: this.pageInfo.startCursor
    };
  }

  currentPageInfo(): PaginationPageInfo {
    return this.pageInfo;
  }
}
